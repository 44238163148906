import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import { Footer } from '../components/footer'
import styled from 'styled-components'
import fpk from '../components/img/fpk2.png'

const LayoutCatalog = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  position: relative;
  width: 100%;
  max-width: 78rem;
  margin: 0 auto;

  .about_wrapper {
    width: 100%;
    max-width: 580px;
    padding-left: 15px;
  }

  h1{
    font-family: 'GT Walsheim Pro Bold';
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  .about_images {

    margin-top: 10vw;
  }
`

class AboutPage extends React.Component {
  render() {
    return (
      <Layout location="/about">
        <LayoutCatalog>
          <Helmet title="О нас" />
          <div className="about_wrapper">
            <h1>Крымское республиканское отделение ВООПИиК</h1>
            <p>
              Крымское республиканское отделение Всероссийской общественной
              организации «Всероссийское общество охраны памятников истории и
              культуры» является структурным подразделением Всероссийской
              общественной организации «Всероссийское общество охраны памятников
              истории и культуры» – добровольной самоуправляемой общественной
              организации, действующей на основании единого Устава,
              зарегистрированного Министерством юстиции России (Свидетельство о
              регистрации № 340 от 24.10.2002).{' '}
            </p>
            <p>
              На протяжении уже  более чем пятидесяти лет Общество осуществляет
              деятельность по охране, сбережению, популяризации и
              использованию историко-культурного наследия России. Усилиями
              Общества были сохранены тысячи  материальных и духовных шедевров,
              принадлежащих  стране и ее гражданам, среди которых и отдельные
              архитектурные шедевры, и усадебные комплексы, и духовные святыни,
              являющиеся бесценным достоянием народов Российской Федерации и
              одной из основ российской культуры и традиции.{' '}
            </p>
            <p>
              Членами Общества могут быть  как организации, так и частные
              лица. Высшим органом является съезд, созываемый один раз в 5 лет.
              В период между созывами съездов работой Общества руководит
              Центральный совет. Постоянно действующим органом управления
              Общества является его Президиум, возглавляемый Председателем
              Центрального совета. В структурных подразделениях – Совет
              республиканского отделения.{' '}
            </p>
            <p>
              Общество продолжает оставаться самой массовой специализированной
              общественной организацией, которая оказывает эффективное влияние
              на сохранение наследия истории и культуры и на формирование
              бережного отношения к памятникам.{' '}
            </p>
            <p>
              Крымское республиканское отделение Всероссийской общественной
              организации «Всероссийское общество охраны памятников истории и
              культуры» создано 09.02.2015 (дата внесения записи об организации
              в ЕГРЮЛ).{' '}
            </p>
            <p>
              Основные направления деятельности: популяризация идеи сохранения
              объектов культурного наследия гражданами России; изучение
              памятников истории и культуры Крыма; реализация проектов по
              сохранению военно-мемориального наследия крымской земли;
              объединение усилий органов государственной власти, местного
              самоуправления, некоммерческих и коммерческих организаций,
              профессионального сообщества и добровольцев по сохранению
              культурного наследия Республики Крым; обобщение и популяризация
              продуктивных форм и технологий работы с молодежью с целью
              формирования бережного отношения к культурному наследию, развития
              образования и профориентации в области, связанные с изучением,
              использованием, сохранением и популяризацией памятников истории и
              культуры.
            </p>
          </div>
          <div className="about_images">
            {/* <img src={logo} style={{ maxWidth: 240 }} alt="" /> */}
            {/* <h2>Наши спонсоры</h2> */}
            <img src={fpk} style={{ maxWidth: 240 }} alt="" />
          </div>
        </LayoutCatalog>
        <Footer />
      </Layout>
    )
  }
}

export default AboutPage
